const documentsFilters = [
  {
    type: 'date',
    id: 'date',
    label: 'Fecha del resumen',
    value: null,
    value2: null,
    category: 'b',
    operator: 'rango',
    title: 'Fechas',
    operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
    countries: ['PE', 'CL']
  },
  {
    type: 'select',
    isMultiple: true,
    id: 'type',
    value: null,
    label: 'Tipo de resumen',
    choices: [],
    category: 'c',
    countries: ['PE', 'CL'],
    title: 'Información del resumen',
    placeholder: 'Selecciona el tipo de documento'
  },
  {
    type: 'text',
    id: 'serial',
    label: 'Serie',
    value: null,
    category: 'c',
    countries: ['PE'],
    title: 'Información del resumen',
    placeholder: 'Escribe la serie'
  }
]
export {
  documentsFilters
}